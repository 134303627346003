<template>
    <VVVLayoutNew>
        <template #page-title>
            Bulk Claims
        </template>
        <MDBAlert v-if="bulkClaimsAlert" class="mb-4" color="success" static>
            <div class="alert-container">
                <vue-feather type="check-circle" :stroke-width="2.5" />
                <p class="mb-0 alert-message">
                    Our <strong>Bulk Claims Sheet</strong> is best for submitting multiple claims at once. By filling
                    out multiple rows
                    on the sheet, you can seamlessly sync it to your VVV dashboard!
                </p>
                <vue-feather type="x" :stroke-width="2.5" size="18" class="ms-auto" @click="bulkClaimsAlert = false"
                    style="cursor: pointer;" />
            </div>
        </MDBAlert>
        <MDBCard class="shadow mb-3">
            <MDBCardHeader> Guidelines </MDBCardHeader>
            <MDBCardBody>
                <div class="alert-container">
                    <vue-feather type="check-circle" size="14" :stroke-width="2.5" class="me-2"
                        style="color: var(--primary)" />
                    <p class="mb-0 alert-message">
                        Open your dashboard claim sheet from your account or onboarding email, and bookmark it for easy
                        access.
                    </p>
                </div>
                <div class="alert-container">
                    <vue-feather type="check-circle" size="14" :stroke-width="2.5" class="me-2"
                        style="color: var(--primary)" />
                    <p class="mb-0 alert-message">
                        Enter all required information for each claim, ensuring no blank rows between claims for each
                        YouTube URL.
                    </p>
                </div>
                <div class="alert-container">
                    <vue-feather type="check-circle" size="14" :stroke-width="2.5" class="me-2"
                        style="color: var(--primary)" />
                    <p class="mb-0 alert-message">
                        Double-check for any unnecessary spaces or blank fields—these may cause errors in syncing.
                    </p>
                </div>
                <div class="alert-container">
                    <vue-feather type="check-circle" size="14" :stroke-width="2.5" class="me-2"
                        style="color: var(--primary)" />
                    <p class="mb-0 alert-message">
                        Only enter claims that are ready for submission; incomplete rows may prevent all claims from
                        syncing.
                    </p>
                </div>
                <div class="alert-container">
                    <vue-feather type="check-circle" size="14" :stroke-width="2.5" class="me-2"
                        style="color: var(--primary)" />
                    <p class="mb-0 alert-message">
                        Once completed, return to 'My Claimed Videos' and click &nbsp;<strong>“Sync Google
                            Sheet.”</strong>&nbsp;
                        Data will disappear once successfully captured.
                    </p>
                </div>
            </MDBCardBody>
        </MDBCard>
        <MDBCard class="shadow-0">
            <MDBCardBody class="py-0 pb-2 px-0">
                <h6 class="pb-1 fw-bold">Your Bulk Claim Sheet:</h6>
                <MDBInput inputGroup :formOutline="false" wrapperClass="mb-3" v-model="shortenSheetUrl">
                    <MDBBtn class="btn btn-primary" @click="copy(shortenSheetUrl)" title="Copy to Clipboard">
                        <vue-feather type="copy" size="18" :stroke-width="2.5" />
                    </MDBBtn>
                    <MDBBtn class="btn btn-primary" @click="redirectToSheet" title="Open in new Tab">
                        <vue-feather type="arrow-right" size="18" :stroke-width="2.5" />
                    </MDBBtn>
                </MDBInput>
                <h6 class="mb-0 d-flex align-items-center" style="gap: 10px;">
                    <vue-feather type="star" fill="green" stroke="green" :stroke-width="2.5" />
                    <span>
                        Bookmark this and keep this safe!
                    </span>
                </h6>
            </MDBCardBody>
        </MDBCard>
        <MDBToast v-model="copyToast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
            color="success" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Success </template>
            Copied Successfully!
        </MDBToast>
    </VVVLayoutNew>
</template>

<script setup>
import VVVLayoutNew from "@/views/VVVNew/VVVLayoutNew.vue";
import { useUserDetailStore } from "@/store/userDetailStore";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBInput, MDBBtn, MDBAlert, MDBToast } from "mdb-vue-ui-kit";
import { useTitle, useClipboard, useStorage, useEventListener } from "@vueuse/core";
import { ref, onMounted, watch, computed } from "vue";
import VueFeather from 'vue-feather';

import dashboardGuideEn from "@/assets/videos/vvv-dashboard-guide-en.mp4";
import dashboardGuideVi from "@/assets/videos/vvv-dashboard-guide-vi.mp4";
import dashboardGuideZh from "@/assets/videos/vvv-dashboard-guide-zh.mp4";
import dashboardGuideNl from "@/assets/videos/vvv-dashboard-guide-nl.mp4";
import dashboardGuideFr from "@/assets/videos/vvv-dashboard-guide-fr.mp4";
import dashboardGuideDe from "@/assets/videos/vvv-dashboard-guide-de.mp4";
import dashboardGuideId from "@/assets/videos/vvv-dashboard-guide-id.mp4";
import dashboardGuideIt from "@/assets/videos/vvv-dashboard-guide-it.mp4";
import dashboardGuideJa from "@/assets/videos/vvv-dashboard-guide-ja.mp4";
import dashboardGuideKo from "@/assets/videos/vvv-dashboard-guide-ko.mp4";
import dashboardGuideMs from "@/assets/videos/vvv-dashboard-guide-ms.mp4";
import dashboardGuidePl from "@/assets/videos/vvv-dashboard-guide-pl.mp4";
import dashboardGuideRu from "@/assets/videos/vvv-dashboard-guide-ru.mp4";
import dashboardGuideEs from "@/assets/videos/vvv-dashboard-guide-es.mp4";
import dashboardGuideTh from "@/assets/videos/vvv-dashboard-guide-th.mp4";
import dashboardGuideTr from "@/assets/videos/vvv-dashboard-guide-tr.mp4";

const userDetailStore = useUserDetailStore();
const { copy, copied } = useClipboard({ legacy: true })
const copyToast = ref(false);

watch(copied, (value) => {
    if (value) {
        copyToast.value = true;
    }
});

const extractGoogleSheetsId = (url) => {
    const regex = /\/d\/([a-zA-Z0-9-_]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
}

const shortenSheetUrl = computed(() => {
    if (userDetailStore.userDetail.googleSheetLink === null) return '';
    const sheetId = extractGoogleSheetsId(userDetailStore.userDetail.googleSheetLink);
    return `https://docs.google.com/spreadsheets/d/${sheetId}`;
});

onMounted(() => {
    useTitle("Bulk Claims | Very Viral Videos");
    updateVideoUrl();
});

const videoUrl = ref(dashboardGuideEn);
const selectedLanguage = ref(1);

const videoUrlList = ref([
    { text: "English", value: 1, videoSrc: dashboardGuideEn },
    { text: "Vietnamese", value: 2, videoSrc: dashboardGuideVi },
    { text: "Chinese", value: 3, videoSrc: dashboardGuideZh },
    { text: "Dutch", value: 4, videoSrc: dashboardGuideNl },
    { text: "French", value: 5, videoSrc: dashboardGuideFr },
    { text: "German", value: 6, videoSrc: dashboardGuideDe },
    { text: "Indonesian", value: 7, videoSrc: dashboardGuideId },
    { text: "Italian", value: 8, videoSrc: dashboardGuideIt },
    { text: "Japanese", value: 9, videoSrc: dashboardGuideJa },
    { text: "Korean", value: 10, videoSrc: dashboardGuideKo },
    { text: "Malay", value: 11, videoSrc: dashboardGuideMs },
    { text: "Polish", value: 12, videoSrc: dashboardGuidePl },
    { text: "Russian", value: 13, videoSrc: dashboardGuideRu },
    { text: "Spanish", value: 14, videoSrc: dashboardGuideEs },
    { text: "Thai", value: 15, videoSrc: dashboardGuideTh },
    { text: "Turkish", value: 16, videoSrc: dashboardGuideTr }
]);

const updateVideoUrl = () => {
    const videoElement = document.getElementById('local-video');
    const selectedOption = videoUrlList.value.find(option => option.value === selectedLanguage.value);
    if (videoElement && selectedOption) {
        videoElement.pause();
        videoUrl.value = selectedOption.videoSrc;
        videoElement.load();
    }
};

watch(selectedLanguage, () => {
    updateVideoUrl();
});

const redirectToSheet = () => {
    window.open(`${shortenSheetUrl.value}`, '_blank');
};

const bulkClaimsAlert = useStorage('bulkClaimsAlert', true);

useEventListener(window, 'reset-alerts', () => {
    bulkClaimsAlert.value = null;
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    gap: 5px;
}

.btn-outline-primary {
    color: var(--primary);
    border-color: var(--primary)
}

#local-video {
    width: 80%;
}

.alert-container {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
}

.alert-message {
    width: 100%;
}

@media (max-width: 767px) {
    .alert-message {
        width: 75%;
    }
}

.card-header {
    color: black !important;
    font-family: 'GilroyBold', sans-serif;
    background-color: white;
}
</style>
